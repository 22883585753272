@import '../../../styles/customMediaQueries.css';

.root {
  position: relative;
  display: inline-block;
}

.popup {
  /* Layout */
  margin-top: 7px;
  min-width: 300px;

  /* By default hide the content */
  opacity: 0;
  display: block;
  visibility: hidden;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Borders */
  border-radius: 8px;
  box-shadow: var(--boxShadowPopup);
  transition: var(--transitionStyleButton);
  border-top: 1px solid var(--colorGrey100);
}

.popupSize {
  padding: 15px 30px;
}

.isOpen {
  opacity: 1;
  display: block;
  visibility: visible;
  pointer-events: auto;
}

/* Design for boolean popup button */
.label,
.labelSelected {
  /* Layout */
  margin: 0;
  width: auto;
  min-height: 42px;
  padding: 0px 12px;

  gap: 8px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Font */
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.2px;

  cursor: pointer;
  border-radius: 8px;
  transition: all var(--transitionStyleButton);

  &:disabled {
    cursor: not-allowed;
  }

  @media (--vwMaxM) {
    width: 100%;
  }
}

.label {
  /* Button borders */
  border: 1px solid var(--colorTextLightGray);

  /* Button colors */
  color: var(--colorTextGray);
  background-color: var(--colorOffWhite);

  &:hover,
  &:focus {
    border: 1px solid var(--colorTextGray);
    background-color: var(--colorLightGray);
  }
}

.labelSelected {
  /* Font */
  font-weight: 600;

  /* Button borders */
  border: 1px solid transparent;

  /* Button colors */
  color: var(--colorWhite);
  background-color: var(--marketplaceColor);

  &:hover,
  &:focus {
    background-color: var(--marketplaceColorDark);
  }

  & svg {
    & path {
      fill: var(--colorWhite);
    }
  }
}

.labelEllipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* Design for boolean popup button */
