@import '../../../styles/customMediaQueries.css';

.container {
  width: 100%;
  height: auto;
  margin: auto;
  /* padding: 1rem; */

  display: flex;
  flex-direction: column;

  border-radius: 8px;
  background-color: var(--colorWhite);

  &::-webkit-scrollbar {
    display: none;

    width: 6px;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: var(--colorTextLightGray);
  }

  &::-webkit-scrollbar-corner {
    border-radius: 8px;
  }

  @media (--vwXL) {
    height: 70vh;
    overflow-y: auto;
  }
}

.signupFormContainer {
  gap: 1.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* background-color: red; */

  & h2 {
    margin: 0;
    padding: 0;
    color: #ed553b;
    font-size: 1.4rem;
    line-height: 32px;
    text-align: center;
    font-family: Roboto, sans-serif;
  }

  & input {
    line-height: 32px;
    padding: 7px 16px 7px 16px;
  }
}

.bottomWrapper {
  /* composes: marketplaceModalBottomWrapper from global; */
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  /* background-color: blue; */
}

.signupButton {
  width: 100%;
  padding: 0 2rem;
  font-size: 1rem;
  border-radius: 8px;
  background-color: var(--colorBlue);
}

.loadingIcon {
  width: 28px;
  height: 28px;

  stroke-width: 3px;
  stroke: var(--colorWhite);
}

/* Styling - add media/documents */
.mediaContainer {
  display: grid;
  row-gap: 25px;
  column-gap: 15px;
  grid-template-columns: repeat(1, 1fr);
  /* background-color: #002626; */
}

.maxFileSizeError {
  min-width: 100%;
  margin-bottom: 13px;

  font-size: 13px;
  font-weight: var(--fontWeightBold);

  overflow: hidden;
  color: var(--colorFail);
}

.addDocumnetsInput {
  height: 48px;
  margin: 0 !important;
  padding: 0 !important;

  border: none !important;
  outline: none !important;
  /* background-color: red; */

  &:hover {
    border: none !important;
    outline: none !important;
  }
}

.addDocumnetsInput[type='file'] {
  color: transparent;

  &::file-selector-button {
    margin: 0;
    width: 100%;
    height: 48px;
    display: block;

    text-align: center;
    text-decoration: none;

    border-radius: 8px;
    border-style: dashed !important;
    border: 2px solid var(--colorGrey300);

    color: var(--colorTextGray);
    background-color: var((--colorGrey100));

    cursor: pointer;
    font-weight: var(--fontWeightSemiBold);
    transition: all var(--transitionStyleButton);

    &:hover {
      border: 2px solid var(--colorGrey600);
    }
  }
}

.addDocumentsLabel {
  margin-left: 6px;
  gap: 6px;
  display: flex;
  align-items: center;
  font-size: 14px;
  color: var(--colorGrey400);
}

.addDocumentWrapper {
  float: left;
  overflow: hidden;
  position: relative;

  &::after {
    content: '.';

    height: 0;
    clear: both;
    display: block;
    visibility: hidden;
  }
}

.addDocuments {
  height: 74px;
  background-color: var(--colorWhite);
}

.addDocumentInput {
  display: none;
}

.addDocument {
  /* Layout */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  margin-top: 0;
  padding: 10px;
  display: flex;
  align-items: center;

  /* Behaviour */
  cursor: pointer;
  transition: var(--transitionStyleButton);

  /* Border */
  border-width: 2px;
  border-radius: 6px;
  border-style: dashed;
  border-color: var(--colorGrey400);

  &:hover {
    border-color: var(--colorGrey600);

    .addDocumentsLabel {
      color: var(--colorGrey600);
    }
  }

  @media (--viewportLarge) {
    background-color: var(--colorGrey50);
  }
}

.previewDocumentWrapper {
  position: relative;

  & img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 4px;
    background-color: #e9e8e8;
  }

  & .removeDocument {
    display: flex;
    align-items: center;
    justify-content: center;

    top: 35%;
    right: 5%;
    padding: 0;
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;

    border: none;
    /* background-color: red; */

    & svg {
      position: absolute;
      fill: var(--colorGrey600);
    }

    &:hover {
      border-width: 1px;
      border-radius: 4px;
      border-style: dashed;
      border-color: var(--colorGrey400);
    }
  }
}

.fileBox {
  height: 74px;
  padding: 1rem;
  display: flex;
  justify-content: space-between;

  border-radius: 6px;
  border: 1px solid var(--colorBlack);
  background-color: var(--colorOffWhite);
}

.fileInformation {
  gap: 10px;
  display: flex;

  & svg {
    width: 20px;
  }

  & div {
    display: flex;
    flex-direction: column;
    height: min-content;

    & p {
      margin: 0;
      padding: 0;
      line-height: 18px;
    }
  }
}

.fileName {
  width: 120px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 500;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--colorTextGray);

  &:hover {
    /* color: var(--colorBlue); */
  }
}

.fileSize {
  font-size: 12px;
  color: var(--colorTextLightGray);
}
/* Styling - add media/documents */

/* input related styling */
.signupFormContainer input::placeholder {
  font-size: 1rem;
}

.name label::after,
.email label::after,
.phoneRequired label::after {
  content: '*';
  color: red;
}
/* input related styling */
