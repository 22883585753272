@import '../../styles/customMediaQueries.css';

/* Can be given to Topbar when a modal is opened
 * so that the Topbar is not rendered on top of it.*/
.topbarBehindModal {
  z-index: initial;
}

.topbar {
  width: 100%;

  /* Top Bar's drop shadow should always be visible */
  position: relative;
  z-index: var(--zIndexTopbar) - 1;

  @media (--viewportMedium) {
    top: 0;
    left: 0;
    position: fixed;

    /* We need to raise Topbar above .container */
    z-index: var(--zIndexTopbar);
  }
}

.container {
  /* Layout */
  width: 100%;
  flex-grow: 1;
  display: flex;

  @media (--viewportMedium) {
    position: relative;
    padding-top: calc(var(--topbarHeightDesktop) + var(--subBarHeightDesktop));
    min-height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--vwFigma) {
    padding-top: var(--topbarHeightDesktop);
  }
}

/* Container for side and content */
.layoutWrapperContainer {
  composes: container;
  /* parent aka root is flexbox, this container takes all available space */
  width: 100%;
  flex-shrink: 0;

  /* This container uses flexbox layout */
  display: flex;
  flex-direction: column;

  /* When side nav is shown as a column, Topbar's box-shadow should be visible */
  z-index: calc(var(--zIndexTopbar) - 1);

  @media (--viewportMedium) {
    flex-direction: row;
    justify-content: center;
  }
}

.layoutWrapperFilterColumn {
  display: none;

  @media (--viewportMedium) {
    /* Layout */
    padding: 24px 0 0 24px;

    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  @media (--viewportLarge) {
    /* Layout */
    padding: 40px 0 0 36px;

    flex-direction: column;
    justify-content: flex-start;

    border: none;
    box-shadow: none;
    background-color: transparent;
  }

  @media (min-width: 1440px) {
    padding: 40px 0 0 59px;
    /* padding: 40px 0 0 calc((100vw - 1368px) / 2); */
  }

  @media (--viewportXLarge) {
    padding: 40px 0 0 59px;
    /* padding: 40px 0 0 calc((100vw - 1800px) / 2); */
  }
}

.filterColumnContent {
  width: 210px;
  padding: 0px 24px 0 0;
  /* background-color: olivedrab; */

  @media (--viewportMedium) {
    padding: 0px 24px 24px 0;
  }

  @media (--viewportLarge) {
    width: 240px;
    padding: 0px 36px 24px 0;
  }

  @media (min-width: 1440px) {
    padding: 0px 60px 24px 0;
  }
}

.filter:first-child {
  padding-top: 0;
}

.resetAllButton {
  composes: h5 from global;
  font-weight: var(--fontWeightMedium);
  color: var(--colorGrey300);

  /* Layout */
  display: inline;
  margin: 17px 0 0 0;
  padding: 0;

  /* Override button styles */
  outline: none;
  text-align: left;
  border: none;
  cursor: pointer;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }

  @media (--viewportMedium) {
    margin: 24px 0 0 0;
    padding: 3px 0 5px 0;
  }
}

.layoutWrapperMain {
  /* Expand to the full remaining width of the viewport */
  width: 100%;
  flex-grow: 1;
  margin-left: auto;
  margin-right: auto;
  padding: 12px 24px 12px 24px;

  display: flex;
  flex-direction: column;
  /* background-color: red; */

  @media (--viewportSmall) {
    /* max-width: 520px; */
  }

  @media (--viewportMedium) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    /* max-width: 720px; */
    padding: 24px 24px 0 24px;

    border-left-width: 1px;
    border-left-style: solid;
    border-left-color: var(--colorGrey100);
  }

  @media (--viewportLarge) {
    /**
     * Calculate right padding to match Footer right border
     * and left padding together with border should be 60px.
     */
    /* max-width: 980px; */
    padding: 40px 36px 40px 36px;
  }

  @media (min-width: 1200px) {
    /* max-width: 1140px; */
  }

  @media (min-width: 1400px) {
    /* max-width: 1320px; */
    padding: 40px 59px 40px 59px;
    /* padding: 40px calc((100vw - 1368px) / 2) 40px 59px; */
  }

  @media (--viewportXLarge) {
    /* max-width: 1640px; */
    padding: 40px 59px 40px 59px;
    /* padding: 40px calc((100vw - 1800px) / 2) 40px 59px; */
  }
}

.responsiveContainer {
  composes: globalResponsiveContainer from global;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}

.searchResultContainer {
  padding: 0;
  flex-grow: 1;

  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  /* background-color: orange; */

  @media (--viewportMedium) {
    padding: 0;
    /**
     * .container is using flexbox,
     * This specifies that searchResultContainer is taking 60% from the viewport width
     */
    flex-basis: 50%;
  }

  @media (--viewportLarge) {
    flex-basis: 62.5%;
  }
}

.searchContainer {
  position: relative;
  /* background-color: blue; */

  @media (--viewportLarge) {
    display: none;
  }
}

/* Custom css for search bar */
.searchLink {
  height: 46px;
  padding: 2px;
  flex-grow: 1;
  max-width: unset !important;

  gap: 12px;
  display: flex;
  flex-direction: row;
  /* background-color: yellowgreen; */

  border-radius: 8px;
  border: 1px solid var(--colorTextLightGray);

  @media (--vwMaxM) {
    height: auto;

    gap: 12px;
    display: flex;
    flex-direction: column;
  }

  /* @media (--vwM) and (--viewportMaxLarge) { */
  @media (--viewportMaxLarge) {
    border: none;
  }
}
/* Custom css for search bar */

.error {
  padding: 0;
  margin: 0 0 1.5rem;
  color: var(--colorFail);
}

.searchString {
  /* Search string should not break on white spaces - i.e. line-break should happen before. */
  white-space: nowrap;
}

.mainPanel {
  display: none;

  @media (--viewportMedium) {
    display: flex;
    /* background-color: red; */
  }
}

.mainPanelMapVariant {
  composes: mainPanel;

  /* @media (--viewportMedium) {
    margin-bottom: 20px;
    padding: 18px 0px 0px 0px;
  } */

  /* @media (--viewportLarge) {
    padding: 24px 0px 0px 0px;
  } */
}

.divider {
  width: 100%;
  border: 1px solid var(--colorLightGray);
}

.searchFiltersMobileMap {
  padding: 10px 0px;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersMobileList {
  padding: 16px 0;

  @media (--viewportMedium) {
    display: none;
  }
}

.searchFiltersPanel {
  flex-grow: 1;
  display: flex;
  padding: 0 24px 24px 24px;

  @media (--viewportLarge) {
    padding: 0 36px 36px 36px;
  }
}

.listings {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.listingsForGridVariant {
  composes: listings;
}

.listingsForMapVariant {
  composes: listings;
  padding: 0 24px;

  @media (--viewportLarge) {
    padding: 0 36px;
  }
}

.newSearchInProgress {
  opacity: 0.4;
}

.searchListingsPanel {
  flex-grow: 1;
}

.mapPanel {
  @media (--viewportMedium) {
    /**
     * .container is using flexbox,
     * This specifies that mapPanel is taking 34% from the viewport width
     */
    flex-basis: 50%;

    /* Own layout settings */
    display: flex;
    height: 100%;
  }

  @media (--viewportLarge) {
    flex-basis: 37.5%;
  }
}

.mapWrapper {
  width: 100%;
  height: 100%;
}

.map {
  width: 100vw;
  height: 100vh;

  @media (--viewportMedium) {
    /* Map is fixed so that it doesn't scroll along search results */
    position: fixed;
    top: var(--topbarHeightDesktop);
    right: 0;

    /* Fixed content needs width relative to viewport */
    width: 50vw;
    height: calc(100vh - var(--topbarHeightDesktop));
  }

  @media (--viewportLarge) {
    width: 37.5vw;
  }
}
