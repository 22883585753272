@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  position: relative;
  flex-direction: column;
  background-color: transparent;
}

.searchContainer {
  position: relative;
  margin-bottom: 2rem;
  /* background-color: blue; */

  @media (--viewportLarge) {
    display: none;
  }
}

/* Custom css for search bar */
.searchLink {
  height: 46px;
  padding: 2px;
  flex-grow: 1;
  max-width: unset !important;

  gap: 12px;
  display: flex;
  flex-direction: row;
  /* background-color: yellowgreen; */

  border-radius: 8px;
  border: 1px solid var(--colorTextLightGray);

  @media (--vwMaxM) {
    height: auto;

    gap: 12px;
    display: flex;
    flex-direction: column;
  }
}
/* Custom css for search bar */

.searchOptions {
  width: 100%;
  margin-bottom: 1rem;

  gap: 1rem;
  display: flex;
  align-items: center;
  /* background-color: red; */
}

.searchResultSummary {
  composes: textSmall from global;
  display: flex;
  align-items: center;
  /* background-color: aqua; */
}

.loadingResults {
  composes: textSmall from global;

  /* Cover parent element's space*/
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  position: absolute;

  /* Layout */
  margin: 0;
  padding: 8px 0 0 0;
}

.resultsFound {
  font-weight: 600;
  white-space: nowrap;
  color: var(--colorOrange);
}

.sortyByWrapper {
  margin-left: auto;

  gap: 6px;
  display: flex;
  align-items: center;
  /* background-color: yellowgreen; */
}

.sortyBy {
  composes: marketplaceSmallFontStyles from global;
  /* padding: 8px 8px 8px 8px; */
  font-weight: var(--fontWeightRegular);
}
