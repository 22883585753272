@import '../../../styles/customMediaQueries.css';

.root {
  gap: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--colorWhite);

  /* sticky result summary in mobile */
  top: 0;
  z-index: 1;
  position: sticky;
}

.searchContainer {
  position: relative;
  margin-bottom: 2rem;
  /* background-color: blue; */
}

/* Custom css for search bar */
.searchLink {
  height: 46px;
  padding: 2px;
  flex-grow: 1;
  max-width: unset !important;

  gap: 12px;
  display: flex;
  flex-direction: row;
  /* background-color: yellowgreen; */

  border-radius: 8px;
  border: 1px solid var(--colorTextLightGray);

  @media (--vwMaxM) {
    height: auto;

    gap: 12px;
    display: flex;
    flex-direction: column;
  }
}
/* Custom css for search bar */

.searchResultSummary {
  composes: h3 from global;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;

  color: var(--colorOrange);
  /* background-color: yellowgreen; */

  @media (--viewportMedium) {
    margin-top: 8px;
  }
}

.buttons {
  display: flex;
  align-items: center;

  overflow-x: auto;
  /* background-color: rebeccapurple; */

  & .filterButton {
    margin-right: 1rem;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.mapIcon {
  /* Font */
  composes: marketplaceTinyFontStyles from global;
  font-weight: var(--fontWeightMedium);

  margin: 0;
  width: 72px;
  height: 35px;
  padding: 9px 22px;
  border-radius: 3px;

  /* background map image */
  background-size: cover;
  background-image: url(./images/map_icon216x105.png);
}

.clickbaitContainer {
  /* margin-left: auto; */

  gap: 1rem;
  display: flex;
  align-items: center;

  & button:nth-child(1) {
    & a {
      background-color: var(--marketplaceColor);
    }
  }

  & button:nth-child(2) {
    & a {
      background-color: var(--colorGreen);
    }
  }
}

.singlePostButtonContainer {
  /* margin-left: auto; */
}

.requirementsFormButton {
  margin: 0;
  padding: 0;
  height: auto;
  min-height: auto;
  margin-left: auto;
  padding: 6px 12px;
  margin-right: 1rem;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  font-family: var(--fontFamilyRoboto);

  border: none;
  border-radius: 8px;
  white-space: nowrap;
  color: var(--colorWhite);
  background-color: var(--colorGreen);

  &:hover,
  &:focus,
  &:active {
    border: none;
    box-shadow: none;
    background-color: var(--colorGreen);
  }
}

.namedLinkButton {
  margin: 0;
  padding: 0;
  height: auto;
  min-height: auto;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 8px;
  white-space: nowrap;
  background-color: #20639b;

  & a {
    padding: 6px 12px;

    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    font-family: var(--fontFamilyRoboto);

    color: white;
    border-radius: 8px;
    text-decoration: none;
    background-color: #20639b;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: #20639b;
  }
}

.requirementsFormModalContainer {
  & > div > div > div {
    display: flex;
    justify-content: center;
    /* background-color: yellowgreen; */
  }
}

.modalContainer {
  width: 100%;
  margin: 0 24px;
  padding: 72px 0px;
}

.modalHeadingWrapper {
  padding-top: 1rem;
  padding-bottom: 1.5rem;

  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid var(--colorGrey100);
}

.modalHeading {
  composes: h1 from global;
  margin: 0;
}

.mobileResetButton {
  /* Layout */
  margin: 0;
  width: auto;
  min-height: 42px;
  padding: 0px 12px;

  /* Font */
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0.2px;

  /* Button borders */
  border-radius: 8px;
  border: 1px solid transparent;

  /* Button colors */
  color: var(--colorWhite);
  background-color: var(--marketplaceColor);

  &:hover,
  &:focus {
    box-shadow: none;
    border: 1px solid transparent;
    background-color: var(--marketplaceColorDark);
  }

  &:disabled {
    cursor: not-allowed;
    color: var(--colorGrey400);
    background-color: var(--colorGrey100);
  }
}

.filtersWrapper {
  /* add bottom margin so that the last filter won't be hidden
   * under the mobile safari bottom bar or the "Show results"
   * button bar */
  padding-bottom: 220px;
}

.showListingsContainer {
  left: 0;
  bottom: 0;
  z-index: 11;
  position: fixed;

  width: 100vw;
  padding: 18px 24px;
  background: var(--colorWhite);
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
}

.showListingsButton {
  border-radius: 3px;
  display: inline-block;
  background-color: var(--marketplaceColor);
}
