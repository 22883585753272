@import '../../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

/* New design for search page */
.searchPageContent {
  width: 100%;
  gap: 2.5rem;
  display: flex;
  flex-direction: column;
  /* background-color: yellowgreen; */

  @media (min-width: 1200px) {
    gap: 1.5rem;
    flex-direction: row;
  }
}

.listingPanel {
  gap: 1.5rem;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.header {
  margin: 0;
  padding: 0;
  color: var(--colorOrange);
  font-family: var(--fontFamilyRoboto);
}

.noListing {
  margin: 0;
  padding: 0;
  opacity: 0.4;
  color: var(--colorTextLightGray);
  font-family: var(--fontFamilyRoboto);
}

.listingCards {
  gap: 1.5rem;
  display: flex;
  flex-direction: column;
  /* background-color: red; */
}

.requirementsFormContainer {
  width: 100%;
  position: static;
  /* background-color: red; */

  @media (--vwXL) {
    /* top: 160px; */
    top: 130px;
    width: 35%;
    padding: 1rem;
    position: sticky;
    border-radius: 8px;
    height: fit-content;
    border: 0.5px solid var(--colorLightGray);
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  }

  @media (--vwMaxXL) {
    display: none;
  }

  @media (--vwFigma) {
    top: 84px;
  }
}
/* New design for search page */

.pagination {
  /* margin-top: auto; pushes pagination to the end of the page. */
  margin-top: auto;
  background-color: transparent;
}
