@import '../../../styles/customMediaQueries.css';

.sortBy {
  /* margin-right: 9px; */
}

.sortByDesktop {
  margin-left: auto;
}

.sortByMenuLabel {
  margin: 0;
  width: auto;
  height: 35px;
  min-height: 35px;
  padding: 0px 18px;
  display: inline-block;

  cursor: pointer;
  transition: all var(--transitionStyleButton);

  /* Font */
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.2px;
  font-weight: var(--fontWeightSemiBold);

  /* Button text styles */
  /* Default button text is centered without decorations */
  text-align: center;
  text-decoration: none;

  /* Button borders */
  border-radius: 8px;
  border: 1px solid var(--colorTextLightGray);

  /* Button colors */
  color: var(--colorTextGray);
  background-color: var(--colorOffWhite);

  &:focus,
  &:hover {
    border: 1px solid var(--colorTextGray);
    background-color: var(--colorLightGray);
  }

  &:disabled {
    box-shadow: none;
    cursor: not-allowed;
  }

  @media (--viewportMedium) {
    margin: 0;
    height: auto;
    min-height: 0;
    padding: var(--marketplaceButtonSmallDesktopPadding);

    font-size: 13px;
    line-height: 20px;
    font-weight: var(--fontWeightMedium);
  }
}
